<script>
export default {
  name: "GameActionPage",
  data() {
    return {
      breadcrumb: [
        {
          path: '/game-action',
          label: 'Game action'
        }
      ]
    }
  },
  mounted() {
    this.setBreadcrumb(this.breadcrumb)
  }
}
</script>

<template>
  <div>Game action</div>
</template>

<style scoped lang="scss">

</style>